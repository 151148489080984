import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/martijn/workspace/moncau/src/components/layout.js";
import ProductList from '../components/productList';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Section = makeShortcode("Section");
const Container = makeShortcode("Container");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Section style={{
      minHeight: "10px"
    }} mdxType="Section">
      <Container mdxType="Container">
        <h3>{`Tria i prova-ho`}</h3>
        <hr></hr>
        <h1>{`Assortiment`}</h1>
        <p>{`Treballem amb materies primes de primera qualitat, respectem els temps de fermentació i creació per tot el que fem. `}</p>
        <p>{`Aqui trobaràs productes artesanals de primera qualitat i també algu de fantasia per els nostres Pastissers i Forners. `}</p>
        <p>{`Tot el que fem té una mica de nosaltres perquè treballem amb el cor.`}</p>
      </Container>
    </Section>
    <ProductList mdxType="ProductList" />

    </MDXLayout>;
}
MDXContent.isMDXComponent = true;
      
import { graphql, StaticQuery } from "gatsby";
import Img from "gatsby-image";
import React from "react";
import { Col, Container, Row } from "reactstrap";
import ButtonLink from "./buttonLink";
import { Section } from './section';



class ProductList extends React.Component {


  render() {

    return (<StaticQuery
      query={graphql`
      {
        allMdx {
          edges {
            node {
              id
              parent {
                ... on File {
                  name
                }
              }
              frontmatter {
                title
                category
              }
              fields {
                slug
              }
            }
          }
          categories: distinct(field: frontmatter___category)
        }
      
        productImages: allFile(filter: {sourceInstanceName: {eq: "products"}}) {
          edges {
            node {
              name
              childImageSharp {
                fluid(maxWidth: 400, maxHeight: 266) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        
      }
          
    `}
      render={data => {
        const { edges: posts, categories } = data.allMdx
        const { edges: productImagesArray } = data.productImages

        const productImages = {}
        productImagesArray.map(({node}) => {
          if (!node.childImageSharp)
            return null
          productImages[node.name] = node.childImageSharp
          return null
        })

        return (
          <div className="ProductList">
            {categories.map((category, i) => (
              <Section key={i}>
                <a className="navlink" name={category}></a>
                <Container>

                  <h3>{category}</h3>
                  <hr />

                  <Row>
                  {posts.map(({ node: product }) => {

                    if (product.frontmatter.category !== category)
                      return undefined;


                    return (
                      <Col md={4}  key={product.id} className="product">
                        <h3>{product.frontmatter.title}</h3>
                        {productImages[product.parent.name] && <Img fluid={productImages[product.parent.name].fluid} />}

                        <ButtonLink to={product.fields.slug}>Més informació</ButtonLink>

                      </Col>
                    )

                  })}
                  </Row>
                </Container>
              </Section>
            )
            )}
          </div>
        )
      }}
    />
    )
  }

}


export default ProductList
